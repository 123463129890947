import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import langs from './jsons/langs';

const AVAILABLE_LANGUAGES: string[] = ['hy', 'en'];

const resources = {
	hy: {
		translation: langs.hy
	},
	en: {
		translation: langs.en
	}
};

i18n.use(initReactI18next).init(
	{
		lng: localStorage.getItem('language') || 'hy',
		fallbackLng: AVAILABLE_LANGUAGES,
		debug: false,
		resources,
		returnEmptyString: false,
		interpolation: {
			escapeValue: false
		},
		parseMissingKeyHandler: () => '',
		react: {
			useSuspense: false
		}
	},
	(err, t) => {
		if (err) return console.error(err);
	}
);

export default i18n;
