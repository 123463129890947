import React, {Suspense} from 'react';
import {Layout} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import './App.less';
import Header from './Components/layout/Header';
import AppFooter from './Components/layout/Footer';

const PrivateRoute = ({isPrivate, role, children}: any) => {
	const isAuthenticated = true;
	return isAuthenticated ? children : <div>Access Denied</div>;
};

function App() {
	// @ts-ignore
	const pages: any = useSelector(({routes}) => routes.data);

	const lazyLoadPage = (pagePath: string) => {
		return React.lazy(() => import(`./pages${pagePath}`));
	};

	return (
		<div className="App">
			<Layout className="layout">
				<Header />
				<Layout style={{minHeight: '100vh'}}>
					<Content className="content" style={{flex: 1}}>
						<Suspense fallback={<div>Loading...</div>}>
							<Routes>
								{pages.map(({path, pagePath, navBar}: any, idx: number) => {
									const Component = lazyLoadPage(pagePath);
									return (
										<Route
											path={path}
											key={idx}
											element={
												<PrivateRoute isPrivate={false} role={null}>
													<Component />
												</PrivateRoute>
											}
										/>
									);
								})}
							</Routes>
						</Suspense>
					</Content>
				</Layout>
				<AppFooter />
			</Layout>
		</div>
	);
}

export default App;
