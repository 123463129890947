import {RouteProps} from './utils/types/index';

export const routes: RouteProps = t => [
	{
		path: '/',
		label: 'Home',
		pagePath: '/Home',
		navBar: true
	},
	{
		path: '/services',
		label: 'Services',
		pagePath: '/Services',
		navBar: true
	},
	{
		path: '/about-us',
		label: 'About us',
		pagePath: '/AboutUs',
		navBar: true
	},
	{
		path: '/partners',
		label: 'Partners',
		pagePath: '/Partners',
		navBar: true
	},
	{
		path: '/contact-us',
		label: 'Contact us',
		pagePath: '/ContactUs',
		navBar: true
	},
	{
		path: '/faqs',
		pagePath: '/FAQ',
		navBar: true,
		label: 'FAQs'
	}
];
