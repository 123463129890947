import {configureStore} from '@reduxjs/toolkit';
import routesReducer from './routes.slice';

export const store = configureStore({
	reducer: {
		routes: routesReducer
	}
});

export type AppDispatch = typeof store.dispatch;
