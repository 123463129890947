import {Button, Layout} from 'antd';
import locationIco from './../../../assets/icons/location-blue.png';
import mail from './../../../assets/icons/mail-blue.png';
import phone from './../../../assets/icons/phone-blue.png';
import linkedInLogo from './../../../assets/linkedin.png';
import logo from './../../../assets/main-logo.png';
// import moment from 'moment';
import {Link} from 'react-router-dom';
import './footer.less';
import {link} from 'fs';
import dayjs from 'dayjs';
const {Footer, Content} = Layout;

const AppFooter = () => {
	const footerContactItems = [
		{
			icon: phone,
			title: 'Tel',
			subtitle: (
				<div className="contact-item-subtitle contact-phone">
					<a href="tel:+37460370044">+374 60 370044</a>
				</div>
			)
		},
		{
			icon: mail,
			title: 'Mail',
			subtitle: (
				<div className="contact-item-subtitle contact-mail">
					<a href="mailto:info@itg.am">info@itg.am</a>
				</div>
			)
		},
		{
			icon: locationIco,
			title: 'Address',
			subtitle: (
				<div className="contact-item-subtitle contact-location" style={{maxWidth: 190}}>
					<p>7 Koryun str., off. 3, 0025, Yerevan, Armenia</p>
				</div>
			)
		}
	];
	const footerLink = [
		{title: 'About us', link: '/about-us'},
		{title: 'Contact', link: '/contact-us'},
		{title: 'Privacy policy', link: '/privacy-policy'},
		{title: 'Terms of Use', link: '/terms'}
	];
	return (
		<Footer className="footer" >
			<Content className="footer_content">
				<div className="footer__container">
					<div className="footer__rows">
						<div className="footer__row-item kickstart-item">
							<div className="recommendation-item">
								<div className="recommendation-item__columns">
									<div className="recommendation-item__column kickstart-title">
										<p>Kickstart your project today</p>
									</div>
									<div className="recommendation-item__column">
										<Button className="footer-demo-btn">Request Demo</Button>
									</div>
								</div>
							</div>
						</div>
						<div className="footer__row-item">
							<div className="footer__about-rows">
								<div className="footer__about-row">
									<img
										src={logo}
										alt=""
										className="logo_link__logo"
										onClick={() => {
											window.scrollTo({top: 0, behavior: 'smooth'});
										}}
									/>
								</div>
								<div className="footer__about-row">
									<p>
										We ara a lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
										ad minim veniam, quis nostrud equip ex ea commodo consequat...
										<span>
											<a>Read More</a>
										</span>
									</p>
								</div>
								<div className="footer__about-row">
									<div className="footer_social-links contact-card">
										<div className="footer_social-link">
											<img src={linkedInLogo} alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="footer__row-item">
							<div className="footer__contact-columns">
								{/* EACH ITEM */}
								{footerContactItems.map((elem: any, index: number) => {
									return (
										<div className="footer__contact-column" key={index}>
											<div className="footer__contact-item">
												<div className="contact-card">
													<div className="footer_social-link">
														<img src={elem.icon} alt="" />
													</div>
												</div>
												<div className="contact-item-info">
													<div className="contact-item-title">{elem.title}</div>
													{elem.subtitle}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
						<div className="footer__row-item">
							<div className="footer__links-row">
								<div className="footer__links-column">
									{/* EACH ITEM */}
									{footerLink.map((elem: any, index: number) => {
										return (
											<div className="footer__link" key={index}>
												<Link to={elem.link}>{elem.title}</Link>
											</div>
										);
									})}
								</div>
								<div className="footer__links-column">
									<p>© {dayjs().year()}, All Rights Reserved</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Content>
		</Footer>
	);
};

export default AppFooter;
