import {createSlice} from '@reduxjs/toolkit';
// import {routes} from './../';
import {routes} from './../router';
import {t} from 'i18next';

const routesSlice = createSlice({
	name: 'routes',
	initialState: {data: routes(t)},
	reducers: {
		setRoutes(state, action) {
			state.data = action.payload;
		}
	}
});

export const {setRoutes} = routesSlice.actions;
export default routesSlice.reducer;
